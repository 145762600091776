import React, { PureComponent } from "react";
import { graphql } from "gatsby";
import Layout from "../Layout/Layout";

class PageTemplate extends PureComponent {
  render() {
    const currentPage = this.props.data.wordpressPage;

    return (
      <Layout>
        <h1>{currentPage.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: currentPage.content }} />
        <p>{currentPage.date}</p>
        <p>{currentPage.slug}</p>
        DEFAULT PAGE
      </Layout>
    );
  }
}

export default PageTemplate;

export const pageQuery = graphql`
  query currentPageQuery($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      slug
      id
      date(formatString: "MMMM DD, YYYY")
      acf {
        cover_photo {
          link
        }
      }
    }
  }
`;
